<template>
  <div class="onboarding-info-wrapper">
    <img src="@/assets/hand_wave.gif" alt="Hello" class="onboarding-info-gif" />
    <p class="onboarding-content-title">
      Mucho gusto, <span style="display: inline-block">{{ firstName }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'OnboardingInfo',
  computed: {
    firstName() {
      return this.$store.state.OnboardingModule.firstName
    },
  },
  mounted() {
    this.timeout = setTimeout(
      () => this.$router.replace('/onboarding/food'),
      2000,
    )
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
}
</script>

<style lang="scss" scoped>
.onboarding-info-wrapper {
  margin-top: 3rem;
  display: grid;
  justify-items: center;
  align-content: flex-start;
}
.onboarding-info-gif {
  mix-blend-mode: color-burn;
}
</style>
